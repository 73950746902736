import type { FunctionComponent } from 'react'

import type { BentoTwoFragment } from '../../../../graphql/queries/GetBentoTwo.graphql'
import BentoPieceSquare from '../../pieces/bento-piece-square'
import styles from './bento-two.module.scss'

export const BentoTwo: FunctionComponent<Props> = ({ pieces }) => {
    const squareOne = pieces?.items[0]
    const squareTwo = pieces?.items[1]

    return (
        <div className={styles.container}>
            <div className={styles.flexContainer}>
                <div className={styles.boxOne}>
                    <BentoPieceSquare square={squareOne} />
                </div>
                <div className={styles.boxTwo}>
                    <BentoPieceSquare square={squareTwo} />
                </div>
            </div>
        </div>
    )
}

export default BentoTwo

type Props = Omit<BentoTwoFragment, 'sys' | 'type'> & {
    index?: number
}
